import React, { useState } from 'react';
import '../../styles/views/Layout.scss';
import { useLoadingRoute } from 'react-navi';
import LoadingIndicator from './loadingIndicator';
import Footer from './footer';
import Sidebar from './sidebar';
import Topbar from './topbar';

export default function Layout(props) {
    const { children } = props
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false)
    const isActive = useLoadingRoute()
    return (
        <div className="layout">
            <Topbar
                isMobileSidebarOpen={isMobileSidebarOpen}
                setIsMobileSidebarOpen={setIsMobileSidebarOpen}
            />
            <Sidebar
                isMobileSidebarOpen={isMobileSidebarOpen}
                setIsMobileSidebarOpen={setIsMobileSidebarOpen}
            />
            <div className="wrapper">
                <div className="main">
                    <LoadingIndicator isActive={isActive} />
                    {children}
                </div>
                <Footer />
            </div>
            <div className="background" />
        </div>
    )
}