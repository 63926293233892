import { ENVIRONMENT } from "./utils/types";

const dev = {
    ENVIRONMENT: ENVIRONMENT.DEVELOPMENT,
    FAUCET_URL: 'http://0.0.0.0:8080/v1/faucet',
    NODE_URL: 'http://localhost:26657',
    WS_NODE_URL: 'ws://127.0.0.1:9944',
    SCANNER_URL: 'http://localhost:3032/',
}

const prod = {
    ENVIRONMENT: ENVIRONMENT.PRODUCTION,
    FAUCET_URL: 'https://agence-faucet-uqdesz6m6q-de.a.run.app/v1/faucet',
    NODE_URL: 'https://takecopter.cloud.agence.network',  // TODO: Is this even being used?
    WS_NODE_URL: 'wss://ws.takecopter.cloud.agence.network',
    SCANNER_URL: 'https://scan.takecopter.cloud.agence.network/',
}

export default process.env.NODE_ENV === ENVIRONMENT.PRODUCTION ? prod : dev;
