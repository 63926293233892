
import { SUPPORT_TX_TYPE, COINS, BN_ZERO, BN_TEN_K } from './types';
import moment from 'moment';
import { BN } from '@aetheras/agencejs';
import { formatBalance } from "@polkadot/util";

export const getMsgSigners = (rawMsg) => {
    switch (rawMsg.type) {
        case SUPPORT_TX_TYPE.SEND:
            return rawMsg.value.from_address

        case SUPPORT_TX_TYPE.MINT_ASSET:
            return [rawMsg.value.minter]

        case SUPPORT_TX_TYPE.SEND_ASSET:
            return rawMsg.value.inputs.map(
                (input) => {
                    return input.address
                }
            )

        default:
            return null
    }
}

export const formatCurrency = (num) => {
    try {
        // TODO: #hack un-hardcode this
        return formatBalance(num, { decimals: 18, forceUnit: '-', withSi: false, withUnit: false })
    } catch (e) {
        console.error("Failed to format ", num)
        throw e
    }
}

export const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const milliSecToSec = (time) => {
    return Math.abs(time / 1000)
}

export function toPercentage(num, digit = 2) {
    if (isNaN(num)) {
        return 'N/A'
    }
    return parseFloat(num * 100).toFixed(digit) + '%'
}

export function floorWithDigits(num, digits) {
    const power = Math.pow(10, digits)
    if (isNaN(power)) {
        return 'N/A'
    }
    return (Math.floor(num * power) / power)
}

export function convertTokensToVotingPower(tokens) {
    const votingPower = Math.trunc(tokens / 1000000)
    return votingPower
}

export const shortAddr = (address) => {
    if (!address) {
        return ''
    }

    return `...${address.slice(-8)}`
}

export const reduceStake = (validators) => {
    if (!validators || !validators.length) {
        return BN_ZERO
    }

    return validators.map(v => v.total_stake).reduce((a, b) => a.add(b), BN_ZERO)
}

export const dateToTimestamp = (date) => {
    return moment(date).local().format('YYYY-MM-DD HH:mm:ss')
}

export const denomToSymbol = (denom) => {
    const coin = COINS.find(coin => coin.denom === denom)
    if (coin) {
        return coin.symbol
    } else {
        return denom
    }
}

export const wrapWithTryCatch = async (callback, errorHandler) => {
    try {
        await callback()
    } catch (err) {
        errorHandler(err)
    }
}


export const isMobile = () => {
    const checker = document.getElementById('mobile-checker')
    if (checker) {
        const style = getComputedStyle(checker)
        return style.opacity === "1"
    }
    return false
}

export const getPolkadotExtrinsicMeta = (api, moduleIndex, callIndex) => {
    const metadata = api.runtimeMetadata.get('metadata').asV14;
    const module = metadata.pallets[moduleIndex]
    const calls = module.calls

    let callName = ""
    if (calls.isSome && !calls.isNone) {
        callName = calls.unwrap()[callIndex].get('name')
    }

    return { module: module.get('name'), call: callName }
}

export const extractAuthorFromHeader = (header, sessionValidators) => {
    //Copy from https://github.com/polkadot-js/api/blob/ff23ead797f7373f8ca0556cf51eec5cddaca563/packages/api-derive/src/type/util.ts#L6
    const [citem] = header.digest.logs.filter(({ type }) => type === 'Consensus')

    let author
    if (citem) {
        const [engine, data] = citem.asConsensus

        author = engine.extractAuthor(data, sessionValidators)
        if (author) {
            return author.toString()
        }
    }

    const [pitem] = header.digest.logs.filter(({ type }) => type === 'PreRuntime')
    if (pitem) {
        const [engine, data] = pitem.asPreRuntime

        author = engine.extractAuthor(data, sessionValidators)
        if (author) {
            return author.toString()
        }
    }

    return "N/A"
}

export const bnDivideRatio = (div, idiv) => {
    const newDiv = (BN.isBN(div)) ? div : new BN(div, 10)
    const newIdiv = (BN.isBN(idiv)) ? idiv : new BN(idiv, 10)
    return (!newIdiv.isZero()) ? newDiv.mul(BN_TEN_K).div(newIdiv).toNumber() / 10000 : 0
}

export const onLinkClick = (url) => (e) => {
    e.preventDefault()
    window.open(url)
}