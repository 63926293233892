import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useAgence } from './agence'
import { blockApi, validatorApi } from '../../api/block'
import { BLOCK_BUFFER_SIZE } from '../../utils/types';
import { milliSecToSec, bnDivideRatio, extractAuthorFromHeader } from '../../utils/common';

const BlocksContext = React.createContext({})
const useBlockContext = () => {
	return useContext(BlocksContext)
}

const defaultBlocks = {
	latestBlock: {},
	lastBlockList: [],
	validators: [],
	accumBlockCount: 0,
	accumBlockTimeSecs: 0,
	avgBlockTime: undefined,
	movingAvgBlockTime: undefined,
	movingTxPerSecond: undefined,
}

const BlocksProvider = ({ children }) => {
	const { api, apiReady } = useAgence()
	const [blockState, setBlockState] = useState(defaultBlocks)
	const [header, setHeader] = useState(null)
	const [initBlockList, setInitBlockList] = useState(null)

	const blockService = useMemo(() => blockApi(api, apiReady), [api, apiReady])
	const validatorService = useMemo(() => validatorApi(api, apiReady), [api, apiReady])

	useEffect(() => {
		if (!apiReady) {
			return
		}
		let unsubscribe
		(async () => {
			const callback = (header) => setHeader(header)
			unsubscribe = await api.rpc.chain.subscribeFinalizedHeads(callback)
		})()
		return () => unsubscribe && unsubscribe()
	}, [apiReady, api, setHeader])

	useEffect(() => {
		if (!apiReady) {
			return
		}
		(async () => {
			const init = await blockService.fetchInitBlockList()
			setInitBlockList(init)
		})()
	}, [api, apiReady, blockService])

	useEffect(() => {
		(async () => {

			if (!header || !initBlockList) {
				return
			}

			const blockNumber = +header.number
			const blockHash = await blockService.fetchHash(blockNumber)
			const signedBlock = await blockService.fetchBlockByHash(blockHash)
			const blockEvents = await api.query.system.events.at(blockHash)
			const stakeOverview = await api.derive.staking.overview()
			const validatorCount = stakeOverview.validatorCount.toJSON()
			const activeEra = stakeOverview.activeEra.toJSON()
			const chainStake = await api.query.staking.erasTotalStake(activeEra)
			const totalIssuance = await api.query.balances.totalIssuance()

			const electedInfo = await api.derive.staking.electedInfo()
			const newValidators = electedInfo.info.map(validator => {
				const address = validator.accountId.toJSON()
				const stakers = validator.exposure
				const total_stake = stakers.total.unwrap()
				const self_stake = stakers.own.unwrap()
				const others_stake = stakers.others
				const preference = validator.validatorPrefs.toJSON()

				const stakeInfo = {
					address: address,
					total_stake: total_stake,
					chain_ratio: bnDivideRatio(total_stake, chainStake),
					self_stake: self_stake,
					self_bonded_ratio: bnDivideRatio(self_stake, total_stake),
					comimssion_rate: preference.commission,
					nominator_count: others_stake.length,
				}

				return stakeInfo
			})

			const sessionValidators = newValidators.map(v => v.address)
			const blockAuthor = extractAuthorFromHeader(header, sessionValidators)

			setBlockState(prevState => {
				if (signedBlock) {
					const { extrinsics, timestamp } = blockService.getTxListByBlock(signedBlock, blockEvents)

					const newLatestBlock = {
						extrinsics: extrinsics,
						number: blockNumber,
						hash: blockHash,
						timestamp: timestamp,
						author: blockAuthor
					}

					if (!prevState.isReady) {
						const lastBlockList = prevState.lastBlockList
						const oldestNumber = lastBlockList.length > 0 ? lastBlockList[lastBlockList.length - 1].number : Number.MAX_SAFE_INTEGER
						for (let i = 0; i < initBlockList.length; i++) {
							if (oldestNumber > initBlockList[i].number) {
								prevState.lastBlockList.push(initBlockList[i])
							}
						}

						prevState.accumBlockCount = prevState.lastBlockList.length
						prevState.accumBlockTimeSecs = milliSecToSec(lastBlockList[lastBlockList.length - 1].timestamp - lastBlockList[0].timestamp)
					}

					// TODO: Review this - probably can be optimized
					const newlastBlockList = prevState.lastBlockList
						.filter((block, index) => (index < BLOCK_BUFFER_SIZE - 1) && (block.number < newLatestBlock.number))
						.reduce((oldBlocks, prevBlock) => {
							const isSame = oldBlocks.find(block => block.number === prevBlock.number)
							return isSame ? oldBlocks : [...oldBlocks, prevBlock]
						}, [newLatestBlock])

					const previousBlock = newlastBlockList.length > 1 ? newlastBlockList[1] : newLatestBlock
					const oldestBlock = newlastBlockList[newlastBlockList.length - 1]

					const timeDiffSec = milliSecToSec(newLatestBlock.timestamp - previousBlock.timestamp)
					const movingTimeDiffSec = milliSecToSec(newLatestBlock.timestamp - oldestBlock.timestamp)

					const newAccumBlockCount = prevState.accumBlockCount + 1
					const newAccumBlockTimeSecs = prevState.accumBlockTimeSecs + timeDiffSec
					const newAvgBlockTime = newAccumBlockTimeSecs / (newAccumBlockCount - 1) // Need to subtract one to take into acount time to generate the first one

					const totalMovingAvgTx = newlastBlockList.reduce((prev, prevBlock) => prev + prevBlock.extrinsics.length, 0)

					const newMovingAvgBlockTime = movingTimeDiffSec / (newlastBlockList.length - 1) // Need to subtract one to take into acount time to generate the first one
					const newMovingTxPerSecond = totalMovingAvgTx / movingTimeDiffSec

					return {
						isReady: true,
						latestBlock: newLatestBlock,
						lastBlockList: newlastBlockList,
						validators: newValidators,
						validatorCount: validatorCount,
						accumBlockCount: newAccumBlockCount,
						accumBlockTimeSecs: newAccumBlockTimeSecs,
						avgBlockTime: newAvgBlockTime,
						movingAvgBlockTime: newMovingAvgBlockTime,
						movingTxPerSecond: newMovingTxPerSecond,
						chainStake: chainStake,
						totalIssuance: totalIssuance,
					}
				}
			})
		})()
	}, [api, header, blockService, initBlockList])

	return (
		<BlocksContext.Provider value={{ blockState, initBlockList, blockService, validatorService }}>
			{children}
		</BlocksContext.Provider>
	)
}

export { useBlockContext, BlocksProvider }
