import { Api } from '@aetheras/agencejs';
import { BLOCK_BUFFER_SIZE, BLOCK_SLICE_SIZE } from '../utils/types';

export const agenceService = {
    initialize(settings) {
        this.resetStateAndBuffer(settings)

        this.wsClient = null
        this.agenceClient = null
    },

    resetStateAndBuffer(settings) {
        // this.txCounts = []
        this.accumBlockTimeSecs = 0
        this.accumBlockCount = 0
        this.accumTxCount = 0

        //Settings
        this.wsURL = settings.wsURL
        this.httpURL = settings.httpURL
        this.blockBufferSize = BLOCK_BUFFER_SIZE
        this.blockSliceSize = BLOCK_SLICE_SIZE

        //States
        this.state = {
            validators: [],
            blockBuffer: [],
            blockSlice: [],
            movingAvgBlockTime: 0,
            movingNumTx: 0,
            movingTxPerSecond: 0,
            txPerSec: 0,
            avgBlockTime: NaN,
            message: "",
            pause: false
        }
    },

    getInitState(wsURL) {
        this.initialize(wsURL)
        return this.getCurrentState()
    },

    getCurrentState() {
        return {
            ...this.state,
        }
    },

    handleError(e) {
        this.state.message = e
        this.callbackChainState()
    },

    setPause() {
        return (pause) => {
            this.state.pause = pause
        }
    },

    closeClients() {
        this.wsClient && this.wsClient.close()
        console.log("Client has been closed")
    },

    async openClients() {
        this.wsClient = new Api.WsProvider(this.wsURL, true)
        this.agenceClient = await Api.createReady(this.httpURL)
        this.callbackAgence(this.agenceClient)
        await this.fetchRecentBlocks()
        this.wsClient.subscribe((e) => { this.handleNewBlock(e) })
        this.wsClient.handleError((e) => { this.handleError(e) })
    },

    async fetchRecentBlocks() {
        try {
            const validators = await this.agenceClient.getValidators()
            const recent20BlocksResp = await this.agenceClient.getRecent20Blocks_RPC().then(res => res.block_metas)
            const recent20Blocks = recent20BlocksResp.map(block => {
                block.proposer = validators.find(v => v.hex_address === block.header.proposer_address)
                return block
            })
            this.state.validators = validators
            this.state.blockBuffer = recent20Blocks
            this.state.blockSlice = recent20Blocks
            this.callbackChainState()
        } catch (err) {
            console.log(err)
        }
    },

    refreshClients(settings) {
        this.closeClients()
        this.resetStateAndBuffer(settings)
        this.openClients()
    },

    async handleNewBlock(event) {
        const { blockBuffer } = this.state
        const validators = await this.agenceClient.getValidators()
        this.state.validators = validators

        this.accumBlockCount++

        const currentBlock = event.block

        const block_meta = (await this.agenceClient.getBlockByHeight_RPC(currentBlock.header.height)).block_meta
        currentBlock.block_id = block_meta.block_id

        currentBlock.proposer = validators.find(v => v.hex_address === currentBlock.header.proposer_address)

        blockBuffer.unshift(currentBlock)

        const txCount = currentBlock.header.num_txs
        let movingNumTx = this.state.movingNumTx
        movingNumTx = movingNumTx + txCount
        this.accumTxCount = this.accumTxCount + txCount

        if (blockBuffer.length > this.blockBufferSize) {
            const oldestBlock = blockBuffer.pop()
            const tx = oldestBlock.header.num_txs
            movingNumTx = movingNumTx - tx
        }

        this.state.movingNumTx = movingNumTx

        if (blockBuffer.length > 1) {
            const firstBlock = blockBuffer[blockBuffer.length - 1]
            const windowTimeDiff = new Date(currentBlock.header.time).getTime() - new Date(firstBlock.header.time).getTime()
            const windowTimeDiffSec = Math.abs(windowTimeDiff / 1000)

            this.state.movingTxPerSecond = movingNumTx / windowTimeDiffSec
            this.state.movingAvgBlockTime = windowTimeDiffSec / (blockBuffer.length - 1) // Need to subtract one to take into acount time to generate the first one

            const latestBlock = blockBuffer[1]
            const timeDiffMilliSec = new Date(currentBlock.header.time).getTime() - new Date(latestBlock.header.time).getTime()

            const timeDiffSec = Math.abs(timeDiffMilliSec / 1000)

            this.accumBlockTimeSecs += timeDiffSec
        }

        this.state.blockSlice = blockBuffer.slice(0, this.blockSliceSize)
        this.state.txPerSec = this.accumTxCount / this.accumBlockTimeSecs
        const avgBlockTime = this.accumBlockTimeSecs / this.accumBlockCount - 1 // Need to subtract one to take into acount time to generate the first one
        this.state.avgBlockTime = avgBlockTime === Infinity ? 'N/A' : avgBlockTime
        if (this.callbackChainState) {
            this.callbackChainState()
        }
    },

    subscribeChainState(callback) {
        if (this.callbackChainState) {
            return
        }

        this.callbackChainState = () => {
            if (!this.state.pause) {
                callback(this.getCurrentState())
            }
        }
        return () => { this.callbackChainState = undefined }
    },

    subscribeAgence(callback) {
        this.callbackAgence = callback
        return () => { this.callbackAgence = undefined }
    }
}