import React, { useState, useEffect, useCallback } from 'react'
export default function LoadingIndicator(props) {
    const { isActive } = props
    const [isHide, setIsHide] = useState(false)

    const animationEnd = useCallback(() => {
        setIsHide(true)
    }, [])

    const transitionEnd = useCallback(() => {
        setIsHide(false)
    }, [])

    useEffect(() => {
        const x = document.getElementById("myAnimation")
        x.addEventListener('animationend', animationEnd)
        x.addEventListener('transitionend', transitionEnd)
        return () => {
            x.removeEventListener('animationend', animationEnd)
            x.removeEventListener('transitionend', transitionEnd)
        }
    }, [animationEnd, transitionEnd])

    return (
        <div id="myAnimation" className={`loadingIndicator ${isActive ? "active" : ''} ${isHide ? "hide" : ''}`} />
    )
}