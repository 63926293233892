import 'moment/locale/zh-tw';
import enUS from 'antd/es/locale/en_US';
import zhTW from 'antd/es/locale/zh_TW';

// WARNING: First entry is used as FALLBACK_LNG, do NOT change the order, unless changing the FALLBACK_LNG
const i18nConfig = [
    {
        // Used as FALLBACK_LNG, should be the first entry in config
        code: 'en-US',
        display: "English",
        abbrev: "EN",
        momentLocale: 'en',
        antdLocale: enUS
    },
    {
        code: 'zh-TW',
        display: "中文(繁體)",
        abbrev: "繁中",
        momentLocale: 'zh-tw',
        antdLocale: zhTW
    }
]

export const FALLBACK_LNG = i18nConfig[0]

export default i18nConfig
