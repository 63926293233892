import config from '../config'

export const forgeService = {

    setup(api) {
        this.api = api
    },
    async getForgeByAddress(address) {
        const resp = await fetch(`${config.SCANNER_URL}v1/account-forges/${address}`, {
            method: 'GET',
            mode: 'cors',
        })

        const forges = await resp.json()
        return forges
    }
}
