import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18nConfig, { FALLBACK_LNG } from '../../i18n/config';

import IconDropdownArrow from '../icons/dropdownArrow';

export default function LanguageSelect() {
    const { i18n } = useTranslation()
    const btnRef = useRef(null)
    const [menuOpen, setMenuOpen] = useState(false)

    const [currentLng, setCurrentLng] = useState((() => {
        const result = i18nConfig.find(lng => lng.code === i18n.language) || FALLBACK_LNG
        moment.locale(result.momentLocale)
        return result
    })())

    const changeLanguage = (lng) => {
        setMenuOpen(false)
        if (lng) {
            i18n.changeLanguage(lng.code)
            moment.locale(lng.momentLocale)
            setCurrentLng(lng)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (btnRef.current && !btnRef.current.contains(event.target)) {
                setMenuOpen(false)
            }
        }



        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div className="selector">
            <button
                ref={btnRef}
                className={`select-button ${menuOpen ? "menu-open" : ""}`}
                onClick={() => setMenuOpen(prev => !prev)}
            >
                <span className="label">{currentLng.abbrev}</span>
                <IconDropdownArrow />
            </button>
            <div className="select-menu-align">
                <SelectMenu
                    menuOpen={menuOpen}
                    currentLng={currentLng}
                    onChange={changeLanguage}
                />
            </div>
        </div>
    )
}

const SelectMenu = (props) => {
    const { menuOpen, currentLng, onChange } = props
    const isCurrent = (lng) => {
        return currentLng.code === lng.code
    }

    const itemList = i18nConfig.map((lng, idx) =>
        <button
            key={idx}
            className={`select-item ${isCurrent(lng) ? "current" : ""}`}
            onClick={() => onChange(lng)}
        >
            {lng.display}
        </button>
    )

    return (
        <div
            id="selectMenu"
            className={`select-menu ${menuOpen ? "" : "hide"}`}
        >
            {itemList}
        </div>
    )
}