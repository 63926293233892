import config from '../config'
import { AUCTIONS_LENGTH } from '../utils/types'



export const auctionService = {

    setup(api) {
        this.api = api
    },

    async getAuctionsBySellerAddress(sellerAddr, length = AUCTIONS_LENGTH, page = 1) {
        const colIndex = 2;
        const desc = true

        const resp = await fetch(`${config.SCANNER_URL}v1/user-auctions/${sellerAddr}/${colIndex}/${desc}/${length}/${page}`, {
            method: 'GET',
            mode: 'cors',
        })
        const result = await resp.json()

        return {
            data: result.data,
            total: result.total
        }

    },

    async getAuctionsByBidderAddress(bidderAddr, length = AUCTIONS_LENGTH, page = 1) {
        const colIndex = 2;
        const desc = true

        const resp = await fetch(`${config.SCANNER_URL}v1/auctions-bidded/${bidderAddr}/${colIndex}/${desc}/${length}/${page}`, {
            method: 'GET',
            mode: 'cors',
        })
        const result = await resp.json()

        return {
            data: result.data,
            total: result.total
        }

    },
}