import React, { useState, useContext, useEffect } from 'react'
import { useAgence } from './agence'
import { stakingService } from '../../api/staking'

const StakingContext = React.createContext({})
const useStaking = () => {
    return useContext(StakingContext)
}

const StakingProvider = ({ children }) => {
    const { api, apiReady } = useAgence()
    const [stakingServiceReady, setServiceReady] = useState(false)

    useEffect(() => {
        if (!apiReady || !api) {
            return
        }

        stakingService.setup(api)
        setServiceReady(true)
    }, [api, apiReady])

    return (
        <StakingContext.Provider value={{ stakingServiceReady, stakingService }}>
            {children}
        </StakingContext.Provider>
    )
}

export { useStaking, StakingProvider }
