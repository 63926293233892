import { BN } from '@aetheras/agencejs'

export const TIMESTAMP_TX_TYPE = 'timestamp/set'

export const EXTRINSIC_EVENTS = Object.freeze({
    SYSTEM_EXTRINSIC_SUCCESS: 'system/ExtrinsicSuccess',
    SYSTEM_EXTRINSIC_FAILED: 'system/ExtrinsicFailed',

    TREASURY_DEPOSIT: 'treasury/Deposit'
})

export const SUPPORT_TX_TYPE = Object.freeze({
    // Bank
    SEND: 'balances/transfer',

    // Staking
    BOND_STAKING: 'staking/bond',
    BOND_EXTRA_STAKING: 'staking/bondExtra',
    UNBOND_STAKING: 'staking/unbond',
    REBOND_STAKING: 'staking/rebond',
    VALIDATE_STAKING: 'staking/validate',
    NOMINATE_STAKING: 'staking/nominate',

    // Forge
    CREATE_FORGE: 'forges/createForge',
    CREATE_ASSET_KIND: 'forges/createAssetKind',
    CREATE_ASSET: 'forges/createAsset',

    // Asset
    // SEND_ASSET: 'assets/transfer',

    // Auctions
    CREATE_AUCTION: 'auctions/createAuction',
    BID_AUCTION: 'auctions/bid',
    BUY_AUCTION: 'auctions/buy'
})

export const ACTIVITY_TYPE = Object.freeze({
    SEND: "send",
    ASSET: "asset",
    FORGE: "forge",
    AUCTION: "auction",
    STAKING: "staking"
})

export const TESTNET_VERSION = 'TAKECOPTER'

export const LINK = Object.freeze({
    TESTNET: 'https://github.com/aetheras-io/testnets/tree/master/' + TESTNET_VERSION,
    EVM_EXPLORER: 'https://blockscout.takecopter.cloud.agence.network',
    CONTACT_MAIL: 'mailto:business@aetheras.io',
    githubURL: 'https://github.com/aetheras-io',
    twitterURL: 'https://twitter.com/aetheras_io',
    discordURL: 'https://discord.gg/R9TCtyh',
    IPFS: 'https://ipfs.aetheras.io/ipfs/',
    AGORA_AUCTION_ASSET: 'https://agora-auction-dev.web.app/assets/',
    AGORA_AUCTION_AUCTION: 'https://agora-auction-dev.web.app/auctions/',
})

// buffer size >= slice size
export const BLOCK_BUFFER_SIZE = 20
export const BLOCK_SLICE_SIZE = 20
export const AUCTIONS_LENGTH = 12
export const EXTRINSIC_BUFFER_SIZE = 100

export const ACTIVE_VALIDATORS_LIMIT = 100

// symbol is for display purpose
export const AGORA_COIN = Object.freeze({
    denom: 'HME',
    symbol: 'HME'
})

export const COINS = [
    AGORA_COIN
]

export const STAKING_COIN = AGORA_COIN

export const ERROR_CODE = Object.freeze({
    // WASM_NOT_READY: 2000
})

export const ENVIRONMENT = Object.freeze({
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
    TEST: 'test'
})

export const COLORS = Object.freeze({
    black: '#262525',
    white: '#fff',
    darkGray: '#929aab',
    gray: '#dee0e6',
    lightGray: '#eeeff2',
    red: '#fe5965',

    purple800: '#5439cc',
    purple700: '#9852f9',
    purple600: '#9176ff',
    purple500: '#6b48ff',
    purple400: '#ae9aff',
    purple300: '#98bcff',
    purple200: '#e1daff',
    purple100: '#dfe2fe',

    orange500: '#ff7959',
    orange400: '#ffaf65',
    orange200: '#ffe7d0',

    turquoise500: '#03c8a8',
    turquoise400: '#68e3cf',
    turquoise300: '#a9f1e5',
    turquoise200: '#cff8f1',
})

export const DEFAULT_GRID = Object.freeze({
    gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 5, xxl: 5
})

export const DEFAULT_GUTTER = 16

export const FULL_WIDTH_COL = 24

export const PATH = Object.freeze({
    HOME: '/',
    ACCOUNTS: '/accounts',
    ACCOUNT_DETAIL: '/accounts/:address',
    BLOCKS: '/blocks',
    BLOCK_DETAIL: '/blocks/:height',
    TRANSACTIONS: '/extrinsics',
    TX_DETAIL: '/extrinsics/:extrisicIndex',
    SETTINGS: '/settings',
    VALIDATORS: '/validators',
    VALIDATOR: '/validators/:address',
    FAUCET: '/faucet',
    KIT: '/kit',
    ERROR: '/error/:type',
    SEARCH: '/search/:keyword'
})

export const PATH_TEMPLATE = Object.freeze({
    ACCOUNT_DETAIL: (address) => `${PATH.ACCOUNTS}/${address}`,
    BLOCK_DETAIL: (height) => `${PATH.BLOCKS}/${height}`,
    TX_DETAIL: (extrinsicIndex) => `${PATH.TRANSACTIONS}/${extrinsicIndex}`,
    VALIDATOR: (address) => `${PATH.VALIDATORS}/${address}`,
    ERROR: (type) => `/error/${type}`,
    SEARCH: (keyword) => `/search/${keyword}`,
})

export const IMAGE_PATH = Object.freeze({
    EXPLORER_LOGO: '/images/logo.svg',
    AVATAR_ARMOR: '/images/avatar-armor.svg'
})

export const ERROR_TYPE = {
    NOT_FOUND: '404',
    ACCOUNT_NOT_FOUND: 'account_not_found',
    BLOCK_NOT_FOUND: 'block_not_found',
    TX_NOT_FOUND: 'tx_not_found',
    VALIDATOR_NOT_FOUND: 'validator_not_found'
}

export const DETAIL_PAGE_SIZE_OPTIONS = [
    5,
    10,
    20
]

export const PAGE_SIZE_OPTIONS = [10, 20, 30]
export const DEFAULT_PAGE = 1
export const BN_TEN_K = new BN(10000, 10)
export const BN_ZERO = new BN(0, 10)

export const REGXP = {
    ACCOUNT_ADDRESS: /^[0-9a-zA-Z]{48}$/,
    BLOCK_HEIGHT: /^[0-9,]+$/,
    // TX_HASH: /0[xX][0-9a-zA-Z]{66}$/,
    EXTRINSIC_INDEX: /^[0-9]+-[0-9]+$/,
}