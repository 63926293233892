import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useBlockContext } from './blocks'
import { EXTRINSIC_BUFFER_SIZE } from '../../utils/types'

const TransactionsContext = React.createContext({})
const useTransactions = () => {
    return useContext(TransactionsContext)
}

const TransactionsProvider = ({ children }) => {
    const [state, setState] = useState([])
    const [initState, setInitState] = useState([])
    const { blockState, initBlockList } = useBlockContext()
    const { latestBlock } = blockState

    const transformBlockExtrinsics = useCallback((block) => {
        return block.extrinsics.map((extrinsic, index) => ({
            key: extrinsic.hash + block.number,
            isValid: extrinsic.isValid,
            tx: {
                hash: extrinsic.hash,
                activity: extrinsic,
                number: block.number,
                sequence: index,
            },
            info: {
                height: block.number,
                timestamp: block.timestamp
            }
        }))
    }, [])

    useEffect(() => {
        if (!initBlockList) {
            return
        }

        const initExtrinsics = initBlockList.reduce((totalExtrinsics, block) => {
            const newExtrinsics = transformBlockExtrinsics(block)

            return totalExtrinsics.concat(newExtrinsics)
        }, [])

        setInitState(initExtrinsics)
    }, [initBlockList, transformBlockExtrinsics])

    useEffect(() => {
        if (JSON.stringify(latestBlock) === '{}') {
            return
        }
        setState(prevState => {
            const newExtrinsics = transformBlockExtrinsics(latestBlock)

            const newState = newExtrinsics.concat(
                ((prevState.length > 0)) ? prevState
                    : initState.filter(extrinsic => +extrinsic.info.height < +latestBlock.number)
            )

            return (newState.length > EXTRINSIC_BUFFER_SIZE) ? newState.slice(newState.length - EXTRINSIC_BUFFER_SIZE) : newState
        })
    }, [initState, latestBlock, transformBlockExtrinsics])

    return (
        <TransactionsContext.Provider value={state}>
            {children}
        </TransactionsContext.Provider>
    )
}

export { useTransactions, TransactionsProvider }
