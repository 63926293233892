import * as Util from '@polkadot/util'
import { BN_ZERO } from '../utils/types'
import * as Crypto from '@polkadot/util-crypto'

export const accountApi = (api, apiReady) => ({
    async fetchAccountKeys() {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        await Crypto.cryptoWaitReady()

        const storageNameKey = Crypto.xxhashAsHex("System", 128)
        const storageItemKey = Crypto.xxhashAsHex("Account", 128)
        const accountQueryKey = storageNameKey + storageItemKey.substring(2)
        const accountKeys = await api.rpc.state.getKeys(accountQueryKey)
        return accountKeys
    },
    async fetchAccountByAddr(address) {
        if (!address) {
            return
        }
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }
        const account = await api.query.system.account(address)
        return account
    },
    async fetchAddressByKey(key, keyring) {
        if (!key || !keyring) {
            return
        }
        await Crypto.cryptoWaitReady()
        const node_properties = await api.rpc.system.properties()
        const ss58_prefix = node_properties.ss58Format

        const addressHex = `0x${key.slice(-64)}`
        const u8aData = Util.hexToU8a(addressHex)
        const address = keyring.encodeAddress(u8aData, ss58_prefix)
        return address
    },
    async fetchAccountBalancesByAddr(address) {
        if (!address) {
            return
        }
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        const balancesAll = await api.derive.balances.all(address)

        const stakingInfo = await api.derive.staking.account(address)

        const total = balancesAll.freeBalance.add(balancesAll.reservedBalance)
        const ledger = stakingInfo.stakingLedger
        const unbonding = ledger.unlocking
            .filter(({ era, value }) => value.unwrap().gt(BN_ZERO) && era.unwrap().gt(BN_ZERO))
            .reduce((total, { value }) => total.iadd(value.unwrap()), BN_ZERO)

        const bonded = ledger.active.unwrap()

        return {
            total: total,
            transferrable: balancesAll.availableBalance,
            locked: balancesAll.lockedBalance,
            reserved: balancesAll.reservedBalance,
            bonded: (bonded) ? bonded : BN_ZERO,
            unbonding: unbonding,
            redeemable: stakingInfo.redeemable,
        }
    }
})