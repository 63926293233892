import { useRef, useEffect } from 'react';

let loadingTimer
let isLoading = false
export const useLoading = (loading) => {
    if (loading === null) {
        return isLoading
    }
    const elem = document.getElementById('loading')
    isLoading = loading
    if (!isLoading) {
        const hideLoading = () => {
            elem.classList.remove('isActive')
        }
        const loadingTime = loadingTimer - new Date().getTime()
        if (loadingTime < 0) {
            hideLoading()
            return isLoading
        }
        setTimeout(() => {
            hideLoading()
        }, loadingTime)
    }
    elem.classList.remove('isHide')
    elem.classList.add('isActive')
    loadingTimer = new Date().getTime() + 500
    return isLoading
}

export const useSafeSet = () => {
    const ref = useRef(true)

    useEffect(() => {
        return () => ref.current = false
    }, [])

    const safeSetRef = useRef(
        (func) => {
            if (ref.current && func) {
                func()
            }
        }
    )

    return safeSetRef.current
}