import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useCurrentRoute, Link } from 'react-navi';
import { useTranslation } from 'react-i18next';
import { PATH, LINK, IMAGE_PATH, TESTNET_VERSION } from '../../utils/types';
import IconGit from '../icons/git';
import IconOverview from '../icons/overview';
import IconBlocks from '../icons/blocks';
import IconTxns from '../icons/txns';
import IconValidators from '../icons/validators';
import IconAccounts from '../icons/accounts';
import IconClose from '../icons/close';
import IconTwitter from '../icons/twitter';
import IconDiscord from '../icons/discord';
import IconFaucet from '../icons/faucet';
import IconLaunch from '../icons/launch';
import { isMobile } from '../../utils/common';

const MOBILE_HIDDEN_POSITION = -280
const MOBILE_SHOW_POSITION = 0
const MOBILE_HIDDEN_CRITICAL_POINT = -100

const ROUTE_LINK_LIST = [
    {
        href: PATH.HOME,
        content: "Overview",
        icon: <IconOverview />
    },
    {
        href: PATH.BLOCKS,
        content: "Blocks",
        icon: <IconBlocks />
    },
    {
        href: PATH.TRANSACTIONS,
        content: "Extrinsics",
        icon: <IconTxns />
    },
    {
        href: PATH.VALIDATORS,
        content: "Validators",
        icon: <IconValidators />
    },
    {
        href: PATH.ACCOUNTS,
        content: "Accounts",
        icon: <IconAccounts />
    },
    {
        href: PATH.FAUCET,
        content: "HME Faucet",
        icon: <IconFaucet />
    }
]

const SNS_LINK_LIST = [
    { icon: <IconGit />, href: LINK.githubURL },
    { icon: <IconTwitter />, href: LINK.twitterURL },
    { icon: <IconDiscord />, href: LINK.discordURL }
]
export default function Sidebar(props) {
    const { isMobileSidebarOpen, setIsMobileSidebarOpen } = props
    const [sidebarPosition, setSidebarPosition] = useState(MOBILE_SHOW_POSITION)
    const [overlayOpacity, setOverlayOpacity] = useState(1)
    const [isSidebarMoving, setIsSidebarMoving] = useState(false)
    const [xStart, setXStart] = useState(null)
    const prevIsMobileRef = useRef(isMobile())
    const { t } = useTranslation()

    const handleResize = useCallback(() => {
        const isMobileTemp = isMobile()
        if (!prevIsMobileRef.current && isMobileTemp) {
            setSidebarPosition(MOBILE_HIDDEN_POSITION)
        } else if (prevIsMobileRef.current && !isMobileTemp) {
            setSidebarPosition(MOBILE_SHOW_POSITION)
        }
        prevIsMobileRef.current = isMobileTemp
    }, [setSidebarPosition])

    const handleTouchStart = useCallback((event) => {
        setXStart(event.changedTouches[0].clientX)
    }, [setXStart])

    const handleTouchMove = useCallback((event) => {
        const xCurrent = event.changedTouches[0].clientX
        let xDiff = xCurrent - xStart

        if (xDiff < MOBILE_HIDDEN_POSITION) {
            xDiff = MOBILE_HIDDEN_POSITION
        } else if (xDiff > MOBILE_SHOW_POSITION) {
            xDiff = MOBILE_SHOW_POSITION
        }

        setSidebarPosition(xDiff)
        setIsSidebarMoving(true)
        setOverlayOpacity(1 - xDiff / MOBILE_HIDDEN_POSITION)
    }, [xStart])

    const handleTouchEnd = useCallback((event) => {
        const xCurrent = event.changedTouches[0].clientX
        const xDiff = xCurrent - xStart
        setIsSidebarMoving(false)

        if (xDiff < MOBILE_HIDDEN_CRITICAL_POINT) {
            setIsMobileSidebarOpen(false)
        } else {
            setSidebarPosition(MOBILE_SHOW_POSITION)
            setOverlayOpacity(1)
        }
    }, [xStart, setIsMobileSidebarOpen])

    const closeSidebar = useCallback(() => setIsMobileSidebarOpen(false), [setIsMobileSidebarOpen])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize])

    useEffect(() => {
        if (!isMobileSidebarOpen) {
            return
        }
        const sidebar = document.getElementById('sidebar-nav')
        sidebar.addEventListener("touchstart", handleTouchStart)
        sidebar.addEventListener("touchmove", handleTouchMove)
        sidebar.addEventListener("touchend", handleTouchEnd)
        document.addEventListener("scroll", closeSidebar)
        return () => {
            sidebar.removeEventListener("touchstart", handleTouchStart)
            sidebar.removeEventListener("touchmove", handleTouchMove)
            sidebar.removeEventListener("touchend", handleTouchEnd)
            document.removeEventListener("scroll", closeSidebar)
        }
    }, [isMobileSidebarOpen, handleTouchStart, handleTouchMove, handleTouchEnd, closeSidebar])

    useEffect(() => {
        if (isMobileSidebarOpen) {
            setSidebarPosition(MOBILE_SHOW_POSITION)
            setOverlayOpacity(1)
        } else if (isMobile()) {
            setSidebarPosition(MOBILE_HIDDEN_POSITION)
            setOverlayOpacity(0)
        }
    }, [isMobileSidebarOpen])

    return (
        <>
            <div
                id="sidebar-nav"
                className={`sidebar ${isSidebarMoving ? "isMoving" : ""}`}
                style={{ left: `${sidebarPosition}px` }}
            >
                <div className="sidebar-inner">
                    <button
                        className="closeBtn"
                        onClick={closeSidebar}
                    >
                        <IconClose />
                    </button>
                    <Link
                        className="logoLink"
                        href={PATH.HOME}
                        onClick={closeSidebar}
                    >
                        <img className="logo" src={IMAGE_PATH.EXPLORER_LOGO} alt="logo" />
                    </Link>
                    <div className="sidebar-content">
                        <div>
                            <div className="sidebar-routeLinks">
                                <RouteLinks closeSidebar={closeSidebar} />
                                <a className="btn-navi disabled-ripple sidebar-testnet"
                                    href={LINK.EVM_EXPLORER}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t("EVM Block Explorer")}
                                    <IconLaunch />
                                </a>
                            </div><div className="sidebar-dividingLine"></div>
                            <div className="btn-fake sidebar-testnet">
                                {TESTNET_VERSION}
                                <span className="testnet-content">
                                    <span className="testnet-inner">
                                        {t("Testnet")}
                                    </span>
                                </span>
                                {/* <IconLaunch /> */}
                            </div>

                        </div>
                        <div className="sidebar-SNSLinks">
                            <div className="SNSLink-outer">
                                <SNSLinks />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="sidebar-overlay"
                style={{ opacity: `${overlayOpacity}` }}
                className={`sidebar-overlay${isMobileSidebarOpen ? " isOpen" : ""}`}
                onClick={closeSidebar}
                onTouchStart={closeSidebar}
            />
        </>
    )
}

function RouteLinks(props) {
    const { closeSidebar } = props
    const { t } = useTranslation()
    const currentPath = "/" + useCurrentRoute().url.pathname.split("/")[1]

    return ROUTE_LINK_LIST.map((link, idx) => (
        <Link
            className={`btn-navi disabled-ripple ${currentPath === link.href ? " isCurrent" : ""}`}
            key={idx}
            href={link.href}
            onClick={() => closeSidebar()}
        >
            {link.icon}
            {t(link.content)}
        </Link>
    ))
}

function SNSLinks() {
    return SNS_LINK_LIST.map((link, idx) =>
        <a className="btn-icon disabled-ripple" key={idx} href={link.href} target="_blank" rel="noopener noreferrer" >
            {link.icon}
        </a >
    )
}