import config from '../config';

export const faucetService = {
    // async requestToTestFaucet(to_address, coin, time_limit_hour) {
    async requestToTestFaucet(to_address, address_type) {
        const msg = JSON.stringify({
            address_type,
            to_address,
            // coin: coin,
            // time_limit_hour: time_limit_hour,
        })

        const headers = new Headers({
            'Content-Type': 'application/json'
        })

        const resp = await fetch(config.FAUCET_URL, {
            method: 'POST',
            headers: headers,
            body: msg,
            mode: 'cors',
        })

        console.log(resp)
        if (!resp.ok) {
            let error = resp.statusText
            switch (resp.status) {
                case 429:
                    error = 'Your faucet is in cooldown'
                    break
                case 500:
                    try {
                        const data = await resp.json()
                        if (data.isFaucetError) {
                            error = data.msg
                        }
                    } catch { }
                    break
                default:
                    break
            }
            console.error(error)
            throw Error(error)
        }

        try {
            const data = await resp.json()
            return data
        } catch (e) {
            return
        }
    },
}