import React, { useState, useEffect, useContext } from 'react'
// import { Api, TypeExtensions, PolkadotTypes } from '@aetheras/agencejs'
import { ApiPromise, WsProvider } from '@polkadot/api'
import {
	RpcExtensions,
	TypeExtensions,
	SignedExtensions,
} from '@aetheras/agencejs-extensions'

const SUBSTRATEV3_ACCOUNTINFO_TYPE_HACK = {
	...TypeExtensions,
	AccountInfo: 'AccountInfoWithDualRefCount',
	// 		{
	//         nonce: 'Index',
	//         consumers: 'RefCount',
	//         providers: 'RefCount',
	//         // sufficients: 'RefCount',
	//         data: 'AccountData',
	//     },
}

function create(url, signer) {
	// Initialize the provider to connect to the local node
	const wsProvider = new WsProvider(url)

	// Create unready API
	return new ApiPromise({
		provider: wsProvider,
		signer: signer,
		// types: TypeExtensions,
		types: SUBSTRATEV3_ACCOUNTINFO_TYPE_HACK,
		rpc: RpcExtensions,
		signedExtensions: SignedExtensions,
	})
}

async function getChainInfo(api) {
	const [properties, systemChain, nodeName, nodeVer] = await Promise.all([
		api.rpc.system.properties(),
		api.rpc.system.chain(),
		api.rpc.system.name(),
		api.rpc.system.version(),
	])
	return {
		properties,
		systemChain,
		nodeName,
		nodeVer,
	}
}

const INIT_STATE = {
	types: TypeExtensions,
	apiUrl: null,
	apiConnected: false,
	apiReady: false,
	chainInfo: null,
	errorMsg: null,
}

const AgenceContext = React.createContext({})
const useAgence = () => {
	return useContext(AgenceContext)
}

let _api;
const AgenceProvider = ({ children, url, isDev }) => {
	const [state, setState] = useState(INIT_STATE)
	const [initialized, setInitialized] = useState(false);

	console.log("url: " + url);

	useEffect(() => {
		(async () => {
			console.log("api connecting...")
			// const wsProvider = new WsProvider(url)
			// console.log("here", wsProvider)


			_api = create(url)
			_api.on("connected", () => {
				setState(prev => {
					return { ...prev, apiConnected: true, disconnected: false, errorMsg: null }
				})
			})

			_api.on("disconnected", () => {
				console.log("api disconnected.")
				setState(prev => {
					return { ...prev, apiConnected: false, disconnected: true, errorMsg: 'api disconnected' }
				})
			})

			_api.on('ready', async () => {
				try {
					console.log("loading chain info and metadata...")
					let infoCall = getChainInfo(_api)
					let metadataCall = _api.rpc.state.getMetadata()

					let chainInfo = await infoCall
					let metadata = await metadataCall
					console.log("Chain info and metadata loaded")

					setState(prev => {
						return { ...prev, apiReady: true, chainInfo: chainInfo, metadata: metadata, errorMsg: null }
					})
				} catch (error) {
					console.error('Unable to load chain', error);
					setState(prev => {
						return { ...prev, apiReady: false, errorMsg: 'failed to load chain info: ' + error.message }
					})
				}
			})
			setInitialized(true)
			setState(prev => ({ ...prev, api: _api }))
		})()

		return () => {
			console.log("cleanup api events")
			_api.off('connected')
			_api.off('disconnected')
			_api.off('ready')
		};
	}, [url])

	// #Note this check is quite important as it short circuits the other downstream context users so they dont render.
	// If we didn't have this, other components that rely on "api.isReady" will get a null
	if (!initialized) {
		return null
	}

	return (
		<AgenceContext.Provider value={state}>
			{children}
		</AgenceContext.Provider >
	)
}

export { useAgence, AgenceProvider }
