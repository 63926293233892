import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-navi';
import { PATH, IMAGE_PATH } from '../../utils/types';
import LanguageSelect from '../shared/languageSelect';
import SearchBar from '../shared/searchBar';
import IconMenu from '../icons/menu';
import { useTranslation } from 'react-i18next';

const MOBILE_HIDDEN_POSITION = 4
const MOBILE_SHOW_POSITION = 52
const MOBILE_RWD_WIDTH = 960

export default function Topbar(props) {
    const { isMobileSidebarOpen, setIsMobileSidebarOpen } = props
    const [isTouchScroll, setIsTouchScroll] = useState(false)
    // Please do NOT call setSearchbarCurrentTop() directly.  Call setSearchbarTop() to set searchbarCurrentTop
    const [searchbarCurrentTop, setSearchbarCurrentTop] = useState(0)
    const setSearchbarTop = useCallback((newHeight) => {
        windowState.current.searchbarHeight = newHeight
        setSearchbarCurrentTop(newHeight)
    }, [])

    const [isMobileStyle, setIsMobileStyle] = useState(false)
    const windowState = useRef({
        searchbarHeight: 0,
        scrollY: 0,
        width: document.body.clientWidth,
        isInTouch: false,
        isOpen: true,
    })


    const { t } = useTranslation()

    const handleScroll = useCallback(() => {
        const prevState = windowState.current
        const diffScrollY = prevState.scrollY - window.scrollY
        const searchbarNewHeight = prevState.searchbarHeight + diffScrollY
        prevState.scrollY = window.scrollY

        const scrolledPastBottom = prevState.scrollY + window.screen.height >= document.documentElement.scrollHeight

        if ((searchbarNewHeight > MOBILE_SHOW_POSITION) ||
            prevState.scrollY <= 0 ||
            (!scrolledPastBottom && !prevState.isInTouch && diffScrollY > 0)) {

            setSearchbarTop(MOBILE_SHOW_POSITION)

        } else if (searchbarNewHeight < MOBILE_HIDDEN_POSITION ||
            scrolledPastBottom ||
            (!prevState.isInTouch && diffScrollY < 0)) {

            setSearchbarTop(MOBILE_HIDDEN_POSITION)

        } else if (prevState.isInTouch) {
            setIsTouchScroll(true)
            setSearchbarTop(searchbarNewHeight)
        }
    }, [setSearchbarTop])

    const handleTouchStart = useCallback(() => {
        windowState.current.isInTouch = true
        windowState.current.isOpen = (windowState.current.searchbarHeight === MOBILE_SHOW_POSITION)
    }, [])

    const handleTouchEnd = useCallback(() => {
        const prevState = windowState.current
        windowState.current.isInTouch = false
        setIsTouchScroll(false)

        if (prevState.isOpen && prevState.searchbarHeight < MOBILE_SHOW_POSITION) {
            setSearchbarTop(MOBILE_HIDDEN_POSITION)
        } else if (!prevState.isOpen && prevState.searchbarHeight > 0) {
            setSearchbarTop(MOBILE_SHOW_POSITION)
        }
    }, [setSearchbarTop])

    const handleResize = useCallback(() => {
        const prevState = windowState.current
        const previousWindowWidth = prevState.windowWidth
        prevState.windowWidth = document.body.clientWidth

        if (prevState.windowWidth === previousWindowWidth) {
            return
        }

        if (document.body.clientWidth < MOBILE_RWD_WIDTH) {
            setIsMobileStyle(true)
            setSearchbarTop(MOBILE_SHOW_POSITION)
        } else {
            setIsMobileStyle(false)
            setSearchbarTop(0)
        }
    }, [setSearchbarTop])

    useEffect(() => {
        if (document.body.clientWidth < MOBILE_RWD_WIDTH) {
            setIsMobileStyle(true)
            setSearchbarTop(MOBILE_SHOW_POSITION)
        } else {
            setIsMobileStyle(false)
            setSearchbarTop(0)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize, setSearchbarTop])

    useEffect(() => {
        if (isMobileSidebarOpen || document.body.clientWidth >= MOBILE_RWD_WIDTH) {
            return
        }

        window.addEventListener('scroll', handleScroll)
        document.addEventListener('touchstart', handleTouchStart)
        document.addEventListener('touchend', handleTouchEnd)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            document.removeEventListener('touchstart', handleTouchStart)
            document.removeEventListener('touchend', handleTouchEnd)
        }
    }, [handleTouchStart, handleTouchEnd, handleScroll, isMobileStyle, isMobileSidebarOpen])


    return (
        <div className="topbar">
            <div className="topbar-inner">
                <button
                    className="menuBtn"
                    onClick={() => {
                        setIsMobileSidebarOpen(true)
                    }}
                >
                    <IconMenu />
                </button>
                <Link className="logoLink" href={PATH.HOME}>
                    <img
                        className="topbar-logo"
                        src={IMAGE_PATH.EXPLORER_LOGO}
                        alt="logo"
                    />
                </Link>
                <div className="lngSelector">
                    <LanguageSelect />
                </div>
            </div>
            <div
                id="topbar-searchbar"
                className={`searchbar ${isTouchScroll ? 'isMoving' : ''}`}
                style={{ top: `${searchbarCurrentTop}px` }}
            >
                <SearchBar placeholder={t("Search by extrinsicIndex, blockHeight, accountAddress")} />
            </div>
        </div>
    )
}
