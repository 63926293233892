import React from 'react';
import { compose, mount, redirect, withView, route, map } from 'navi';
import { View } from 'react-navi';
import Config from '../config';
import { PATH, ENVIRONMENT, ERROR_TYPE, PATH_TEMPLATE } from '../utils/types';
import Layout from '../components/layout/layout';
import { useLoading as setLoading } from '../utils/hooks';

const devOnly = () => matcher => map(async () => {
    if (Config.ENVIRONMENT === ENVIRONMENT.DEVELOPMENT) {
        return route(matcher)
    }
    return redirect('/')
})

const withLoadingWrapping = async (component) => {
    setLoading(false)
    return component
}

export default compose(
    withView((_, context) => {
        return (
            <Layout context={context}>
                <View />
            </Layout>
        )
    }),
    mount({
        [PATH.HOME]: route({
            getView: () => import('../views/Home')
        }),

        [PATH.BLOCKS]: route({
            getView: () => import('../views/BlockTable')
        }),

        [PATH.BLOCK_DETAIL]: map(async (req) => {
            const height = req.params.height
            if (!height) {
                return redirect(PATH_TEMPLATE.ERROR(ERROR_TYPE.BLOCK_NOT_FOUND))
            }
            return route({
                getView: async (_, context) => {
                    const { default: BlockDetail } = await import('../views/BlockDetail')
                    return (
                        <BlockDetail height={height} />
                    )
                }
            })
        }),

        [PATH.TRANSACTIONS]: route({
            getView: async (_, context) => {
                const { default: TransactionTable } = await import('../views/TransactionTable')
                return (
                    <TransactionTable />
                )
            }
        }),

        [PATH.TX_DETAIL]: map(async (req) => {
            const extrisicIndex = req.params.extrisicIndex
            if (!extrisicIndex) {
                return redirect(PATH_TEMPLATE.ERROR(ERROR_TYPE.TX_NOT_FOUND))
            }
            return route({
                getView: async (_, context) => {
                    const { default: TransactionDetail } = await import('../views/TransactionDetail')
                    return (
                        <TransactionDetail extrisicIndex={extrisicIndex} />
                    )
                }
            })
        }),

        [PATH.VALIDATORS]: route({
            getView: async (_, context) => {
                const { default: ValidatorTable } = await import('../views/ValidatorTable')
                return (
                    <ValidatorTable />
                )
            }
        }),

        [PATH.VALIDATOR]: map(async (req) => {
            const address = req.params.address
            if (!address) {
                return redirect(PATH_TEMPLATE.ERROR(ERROR_TYPE.VALIDATOR_NOT_FOUND))
            }
            return route({
                getView: async (req, context) => {
                    const { default: ValidatorDetail } = await import('../views/ValidatorDetail')
                    return (
                        <ValidatorDetail address={address} />
                    )
                }
            })
        }),

        [PATH.ACCOUNTS]: route({
            getView: async (_, context) => {
                const { default: AccountTable } = await import('../views/AccountTable')
                return (
                    <AccountTable />
                )
            }
        }),

        [PATH.ACCOUNT_DETAIL]: map(async req => {
            const address = req.params.address
            if (!address) {
                return redirect(PATH_TEMPLATE.ERROR(ERROR_TYPE.ACCOUNT_NOT_FOUND))
            }
            return route({
                getView: async (req, context) => {
                    const { default: AccountDetail } = await import('../views/AccountDetail')
                    return (
                        <AccountDetail address={address} />
                    )
                }
            })
        }),

        [PATH.FAUCET]: route({
            getView: async (_, context) => {
                const { default: TestFaucet } = await import('../views/TestFaucet')
                return withLoadingWrapping(
                    <TestFaucet
                        faucetService={context.faucetService}
                    />
                )
            }
        }),

        [PATH.SETTINGS]: devOnly()({
            getView: async (_, context) => {
                const { default: Settings } = await import('../views/Settings')
                return withLoadingWrapping(
                    <Settings
                        handleUpdateSettings={context.handleUpdateSettings}
                        settings={context.settings}
                    />
                )
            }
        }),

        [PATH.KIT]: route({
            getView: async () => {
                const { default: Kit } = await import('../views/Kit')
                return withLoadingWrapping(<Kit />)
            }
        }),

        [PATH.ERROR]: route({
            getView: async (req) => {
                const { default: ErrorPage } = await import('../views/ErrorPage')
                return withLoadingWrapping(
                    <ErrorPage type={req.params.type} />
                )
            }
        }),

        [PATH.SEARCH]: route({
            getView: async (req, context) => {
                const { default: Search } = await import('../views/Search')
                return (
                    <Search
                        keyword={decodeURIComponent(req.params.keyword)}
                    />
                )
            }
        }),

        '*': redirect(PATH_TEMPLATE.ERROR(ERROR_TYPE.NOT_FOUND), { exact: false })
    })
)