export const stakingService = {

    setup(api) {
        this.api = api
    },
    async getNominationsByAddress(address) {
        const stakingInfo = await this.api.derive.staking.electedInfo()
        const nominatedInfo = stakingInfo.info.reduce((accumulator, validator) => {
            const nominaitonArray = validator.exposure.others
            const requiredNomination = nominaitonArray.find(n => {
                return n.who.toString() === address
            })
            if (requiredNomination) {
                accumulator.push({
                    validator: validator.accountId.toString(),
                    amount: requiredNomination.value.toString(),
                })
            }
            return accumulator
        }, [])

        return nominatedInfo
    },
}
