import React from 'react';
import { LINK } from '../../utils/types';

export default function Footer() {
    return (
        <div className="footer">
            <span>
                {`Copyright © 2022 Heimdal Holdings Corp. All rights reserved | `}
                <a href={LINK.CONTACT_MAIL} className="link">Contact</a>
            </span>
        </div>
    )
}