import React, { useState, useContext, useEffect } from 'react'
import { useAgence } from './agence'
import { forgeService } from '../../api/forges'

const ForgesContext = React.createContext({})
const useForges = () => {
    return useContext(ForgesContext)
}

const ForgesProvider = ({ children }) => {
    const [forgeSerivceReady, setServiceReady] = useState(false)
    const { api, apiReady } = useAgence()

    useEffect(() => {
        if (!apiReady || !api) {
            return
        }

        forgeService.setup(api)
        setServiceReady(true)
    }, [api, apiReady])

    return (
        <ForgesContext.Provider value={{ forgeSerivceReady, forgeService }}>
            {children}
        </ForgesContext.Provider>
    )
}

export { useForges, ForgesProvider }
