
import { bnDivideRatio, extractAuthorFromHeader } from '../utils/common'
import { TIMESTAMP_TX_TYPE, EXTRINSIC_EVENTS, BN_ZERO } from '../utils/types'
import { BN } from '@aetheras/agencejs'

export const blockApi = (api, apiReady) => ({
    async fetchInitBlockList() {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        console.log("Fetching Initial blocks")
        const blockCalls = []
        const finalizedHead = await api.rpc.chain.getFinalizedHead()

        const getBlock = await this.fetchBlockByHash(finalizedHead)
        const blockNumber = +getBlock.block.header.number - 1
        for (let n = blockNumber; n > blockNumber - 4; n--) {
            const blockPromise = this.fetchFullBlockInfoByNum(n);
            blockCalls.push(blockPromise)
        }

        const latestBlocks = []
        for (let i = 0; i < blockCalls.length; i++) {

            const blockInfo = await blockCalls[i]
            latestBlocks.push(blockInfo)
        }

        return (latestBlocks)
    },
    async fetchHash(number) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }
        let hash
        if (number) {
            hash = await api.rpc.chain.getBlockHash(number)
        }
        else {
            hash = await api.rpc.chain.getBlockHash()
        }
        return hash && hash.toString()
    },

    async fetchFullBlockInfoByNum(n) {
        const blockHash = await this.fetchHash(n)
        const signedBlock = await this.fetchBlockByHash(blockHash)
        const events = await api.query.system.events.at(blockHash)
        let timestamp
        const extrinsics = signedBlock.block.extrinsics
        const handledExtrinsics = extrinsics.map((extrinsic, index) => {
            const method = extrinsic.method
            const type = `${method.section}/${method.method}`
            const args = method.args
            const successIndex = this.findExtrinsicEventIndex(index, events, EXTRINSIC_EVENTS.SYSTEM_EXTRINSIC_SUCCESS)
            const isValid = (successIndex > -1)

            if (type === TIMESTAMP_TX_TYPE) {
                timestamp = +args
            }
            const signer = extrinsic.signer.toString()
            const argsEntries = {}
            method.argsEntries.forEach(v => {
                argsEntries[v[0]] = v[1]
            });

            return {
                signer: signer,
                hash: extrinsic.hash.toString(),
                type: type,
                args: argsEntries,
                isValid: isValid,
            }
        }).filter(extrinsic => Object.keys(extrinsic).length !== 0)

        const blockAuthor = await validatorApi(api, apiReady).fetchAuthorByBlock(blockHash, signedBlock)

        return {
            extrinsics: handledExtrinsics,
            number: n,
            hash: blockHash,
            timestamp: timestamp,
            author: blockAuthor
        }
    },

    async fetchBlockByHash(hash) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }
        if (hash) {
            return await api.rpc.chain.getBlock(hash)
        }
        return await api.rpc.chain.getBlock()
    },
    async fetchBlockByNumber(number) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }
        const blockHash = await this.fetchHash(number)
        const signedBlock = await this.fetchBlockByHash(blockHash)

        return signedBlock
    },
    async fetchBlockEventsByNumber(number) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }
        const blockHash = await this.fetchHash(number)
        const blockEvents = await this.fetchBlockEventsByHash(blockHash)
        return blockEvents
    },
    async fetchBlockEventsByHash(hash) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }
        const blockEvents = await api.query.system.events.at(hash)
        return blockEvents
    },
    findExtrinsicEventIndex(extrinsicIndex, blockEvents, eventType) {
        const eventIndex = blockEvents.findIndex((blockEvent) => {
            const isRequiredEvent = `${blockEvent.event.section}/${blockEvent.event.method}` === eventType
            const phase = blockEvent.phase.toJSON()
            const isRequiredExtrinsic = phase.applyExtrinsic === extrinsicIndex

            return isRequiredEvent && isRequiredExtrinsic
        })

        return eventIndex
    },
    getTxListByBlock(signedBlock, blockEvents) {
        if (!signedBlock || !signedBlock.block || !blockEvents) {
            return
        }
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }
        let timestamp
        const extrinsics = signedBlock.block.extrinsics.reduce((old, next, extrinsicIndex) => {

            const rawExtrinsic = next
            const method = rawExtrinsic.method
            const type = `${method.section}/${method.method}`
            const args = method.args
            if (type === TIMESTAMP_TX_TYPE) {
                timestamp = +args
            }
            const signer = rawExtrinsic.signer.toString()
            const hash = next.hash.toString()

            const argsEntries = {}
            method.argsEntries.forEach(v => {
                argsEntries[v[0]] = v[1]
            });

            const successIndex = this.findExtrinsicEventIndex(extrinsicIndex, blockEvents, EXTRINSIC_EVENTS.SYSTEM_EXTRINSIC_SUCCESS)
            const isValid = (successIndex > -1)

            return old.concat({ signer, hash, type, args: argsEntries, isValid, sequence: extrinsicIndex })
        }, [])

        return { extrinsics, timestamp }
    },
    async getBestNumberFinalized() {
        const latestBN = await api.derive.chain.bestNumberFinalized()

        return latestBN.toNumber()
    },
    async findExtrinsicHashInBlock(blockHeight, extrinsicSequence) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        const blockData = await this.fetchBlockByNumber(blockHeight)
        if (!blockData) {
            return
        }

        const extrinsics = blockData.block.extrinsics
        if (extrinsics.length < +extrinsicSequence + 1) {
            return
        }

        const extrinsic = extrinsics[extrinsicSequence]
        return extrinsic.hash.toString()

    },
    async getExtrinsicDetailByBlock(blockHeight, txHash) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        const blockData = await this.fetchBlockByNumber(blockHeight)
        if (!blockData) {
            return
        }

        const blockEvents = await this.fetchBlockEventsByNumber(blockHeight)

        const extrinsics = blockData.block.extrinsics
        let extrinsicIndex = -1
        let requiredMethod
        let timestamp
        for (let i = 0; i < extrinsics.length; i++) {
            const extrinsic = extrinsics[i]

            const method = extrinsic.method
            const type = `${method.section}/${method.method}`
            const args = method.args

            if (type === TIMESTAMP_TX_TYPE) {
                timestamp = +args
            }

            if (extrinsic.hash.toString() === txHash) {
                extrinsicIndex = i
                requiredMethod = method
            }
        }

        if (extrinsicIndex < 0) {
            return
        }

        const depositEventIndex = this.findExtrinsicEventIndex(extrinsicIndex, blockEvents, EXTRINSIC_EVENTS.TREASURY_DEPOSIT)
        const treasuryDeposit = (depositEventIndex > -1) ? blockEvents[depositEventIndex].event.data[0] : 0
        //20% fee will go to block producer, 80% fee will go to treasury
        const fee = treasuryDeposit / 80.0 * 100.0

        const successIndex = this.findExtrinsicEventIndex(extrinsicIndex, blockEvents, EXTRINSIC_EVENTS.SYSTEM_EXTRINSIC_SUCCESS)
        const isValid = (successIndex > -1)

        return {
            module: requiredMethod.section,
            call: requiredMethod.method,
            args: requiredMethod.argsEntries,
            fee: fee,
            timestamp: timestamp,
            isValid: isValid,
            hash: txHash,
        }
    }
})

export const validatorApi = (api, apiReady) => ({
    async fetchValidators(hash) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        if (hash) {
            return await api.query.session.validators.at(hash)
        } else {
            return await api.query.session.validators()
        }
    },
    async fetchAuthorByBlock(hash, block) {
        if (!block) {
            return
        }
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        const validators = await this.fetchValidators(hash)
        const author = extractAuthorFromHeader(block.block.header, validators)

        return author
    },
    async isValidator(stashAddress) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        const bondedOption = await api.query.staking.bonded(stashAddress)
        const nominatorOption = await api.query.staking.nominators(stashAddress)
        return !bondedOption.isNone && nominatorOption.isNone
    },
    async fetchValidatorDetail(stashAddress) {
        if (!apiReady) {
            console.log("api is not ready yet.")
            return
        }

        const prefs = (await api.query.staking.validators(stashAddress)).toJSON()

        const controllerOption = await api.query.staking.bonded(stashAddress)
        const controllerAddress = (controllerOption.isNone) ? stashAddress : controllerOption.unwrap().toString()

        const activeEra = (await api.query.staking.activeEra()).unwrap().toJSON()
        const eraIndex = activeEra.index
        // const eraStartTimeMilli = activeEra.start
        const stakeInfo = (await api.query.staking.erasStakers(eraIndex, stashAddress))
        const total_stake = stakeInfo.total.unwrap()
        const self_stake = stakeInfo.own.unwrap()

        const totalNominations = new BN(0, 10)
        const others_stake = stakeInfo.others.map(nomination => {
            const handled = {
                address: nomination.who.toString(),
                amount: nomination.value.unwrap()
            }

            totalNominations.iadd(handled.amount)

            return handled
        })

        const chainStake = await api.query.staking.erasTotalStake(eraIndex)

        const chain_ratio = bnDivideRatio(total_stake, chainStake)
        const self_bonded_ratio = bnDivideRatio(self_stake, total_stake)

        const nominations = others_stake.map(nomination => {
            return {
                address: nomination.address,
                amount: nomination.amount.toString(),
                ratio: bnDivideRatio(nomination.amount, totalNominations),
            }
        })

        const ownSlashes = await api.derive.staking.ownSlashes(stashAddress)
        const erasRewards = await api.derive.staking.erasRewards()
        const stakerPoints = await api.derive.staking.stakerPoints(stashAddress)

        // const progress = await api.derive.session.progress()
        // const eraLength = progress.eraLength.toJSON()

        const ownRewards = erasRewards.map(({ era, eraReward }) => {
            const pointPair = stakerPoints.find(sp => sp.era.eq(era))
            const ownPoint = pointPair.points
            const eraPoint = pointPair.eraPoints
            const ownReward = (ownPoint.gtn(0) && eraPoint.gtn(0)) ? eraReward.mul(ownPoint).div(eraPoint) : BN_ZERO

            return {
                era,
                reward: ownReward,
            }
        }).reverse()

        //#TODO: calculate estimated era start block by acitiveEra, eraProgress, eraLength

        return {
            stashAddress: stashAddress,
            controllerAddress: controllerAddress,
            commission: (prefs && prefs.commission) ? prefs.commission : +0,
            total_stake: total_stake,
            chain_ratio: chain_ratio,
            self_stake: self_stake,
            self_bonded_ratio: self_bonded_ratio,
            nominations: nominations,
            ownRewards: ownRewards.filter(or => or.reward.gtn(0)),
            ownSlashes: ownSlashes.filter(os => os.total.gtn(0)),
        }
    }
})