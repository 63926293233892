import React, { useState, useContext, useEffect } from 'react'
import { useAgence } from './agence'
import { assetService } from '../../api/assets'

const AssetsContext = React.createContext({})
const useAssets = () => {
    return useContext(AssetsContext)
}

const AssetsProvider = ({ children }) => {
    const { api, apiReady } = useAgence()
    const [assetServiceReady, setServiceReady] = useState(false)

    useEffect(() => {
        if (!apiReady || !api) {
            return
        }

        assetService.setup(api)
        setServiceReady(true)
    }, [api, apiReady])

    return (
        <AssetsContext.Provider value={{ assetServiceReady, assetService }}>
            {children}
        </AssetsContext.Provider>
    )
}

export { useAssets, AssetsProvider }
