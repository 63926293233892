import React, { useState, useEffect } from 'react';
import { useNavigation } from 'react-navi';
import { PATH_TEMPLATE } from '../../utils/types';

// import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export default function SearchBar(props) {
    const { placeholder } = props
    const [keyword, setKeyword] = useState(null)
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        const onClick = (e) => {
            const clickPath = e.path
            const searchOuter = document.getElementById('search-outer')
            if (clickPath && !clickPath.includes(searchOuter)) {
                setIsActive(false)
            }
        }
        const root = document.getElementById('root')
        root.addEventListener('click', onClick)
        return () => {
            root.removeEventListener('click', onClick)
        }
    }, [])

    const navigation = useNavigation()
    const handleSearch = (e) => {
        e.preventDefault()
        const keywordTrimed = keyword && keyword.trim()
        if (!keywordTrimed) {
            return
        }
        setIsActive(false)
        navigation.navigate(PATH_TEMPLATE.SEARCH(encodeURIComponent(keywordTrimed)))

        //Clear content and remove focus from search bar after searching
        setKeyword(null)
        document.getElementById('search-input').blur()
    }

    // const handleIconClick = (e) => {
    //     if (!keyword) {
    //         setIsActive(true)
    //         document.getElementById('search-input').focus()
    //         return
    //     }
    //     handleSearch(e)
    // }

    return (
        <div id="search-outer">

            <form action="" onSubmit={handleSearch}>
                <Input
                    id="search-input"
                    type="search"
                    className={`input search-input ${isActive ? 'isActive' : ''}`}
                    autoComplete="off"
                    placeholder={placeholder}
                    value={keyword}
                    onChange={e => setKeyword(e.target.value)}
                    onFocus={() => setIsActive(true)}
                // TODO: Fix this
                // suffix={(
                //     <SearchOutlined
                //         className="icon"
                //         onClick={handleIconClick}
                //     />
                // )}
                />
            </form>
        </div >
    )
}