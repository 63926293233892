import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { FALLBACK_LNG } from './config';

i18n
    // load translation using xhr -> see /public/locales
    .use(Backend)
    // detect user language
    .use(LanguageDetector)
    // passes i18n down to react-i18next
    .use(initReactI18next)
    // init i18next
    .init({
        fallbackLng: FALLBACK_LNG.code,
        // setting "lng" will disable LanguageDetector
        // lng: 'en-US',
        preload: ['en-US', 'zh-TW'],
        debug: false,

        interpolation: {
            // react already safes from xss
            escapeValue: false
        }
    })

export default i18n