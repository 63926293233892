import config from '../config'
import * as Util from '@polkadot/util'

const handleAssetRespFromAgenceScan = async (assets) => {
    const completeAssets = await Promise.all(assets.map(async (asset) => {
        const ipfsHash = Util.u8aToString(new Uint8Array(asset.data_hash))

        return {
            ...asset,
            id: asset.source_id + 'x' + asset.asset_kind_id + 'x' + asset.asset_id,
            //#TODO: Need to store forgeName in the future 
            forgeName: asset.source_id.toString(),
            ipfs: ipfsHash,
        }
    }))

    return completeAssets
}


export const assetService = {
    setup(api) {
        this.api = api
    },

    async getPaginatedAssetsByURL(url) {
        const resp = await fetch(url, {
            method: 'GET',
            mode: 'cors',
        })

        const result = await resp.json()
        const assets = await handleAssetRespFromAgenceScan(result.data)

        return {
            data: assets,
            total: result.total
        }
    },
    async getPaginatedAssetsByAddress(address, length = 12, page = 1) {
        const desc = true
        const url = `${config.SCANNER_URL}v1/account-assets/${address}/${desc}/${length}/${page}`

        return await this.getPaginatedAssetsByURL(url)
    },
}
