import React, { Suspense, useEffect, useState } from 'react';
import './App.scss';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import { Router, View } from 'react-navi';
import routes from './routes/routes';
import { agenceService } from './api/agence';
import { faucetService } from './api/faucet';
import config from './config';

import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import i18nConfig, { FALLBACK_LNG } from './i18n/config';
import { AgenceProvider } from './components/providers/agence';
import { BlocksProvider } from './components/providers/blocks';
import { TransactionsProvider } from './components/providers/transactions';
import { AccountsProvider } from './components/providers/accounts';
import { ForgesProvider } from './components/providers/forges';
import { AssetsProvider } from './components/providers/assets';
import { StakingProvider } from './components/providers/staking';
import { AuctionsProvider } from './components/providers/auctions';


const DEFAULT_SETTINGS = {
    httpURL: config.NODE_URL,
    wsURL: config.WS_NODE_URL
}

const I18nSettings = (props) => {
    const { children } = props
    const { i18n } = useTranslation()

    const antdLocale = (
        i18nConfig.find(lng => lng.code === i18n.language) || FALLBACK_LNG
    ).antdLocale

    return (
        <ConfigProvider locale={antdLocale}>
            {children}
        </ConfigProvider>
    )
}

export default function App() {
    const [settings, setSettings] = useState(Object.assign({}, DEFAULT_SETTINGS))
    const [isFontLoaded, setIsFontLoaded] = useState(false)

    useEffect(() => {
        document.fonts.ready.then(() => setIsFontLoaded(true))
    }, [])

    const handleUpdateSettings = (settings) => {
        setSettings(settings)
    }

    moment.relativeTimeThreshold('ss', 0)

    useEffect(() => {
        if (isFontLoaded) {
            const elem = document.getElementById('loading')
            elem.classList.add('isReady')
        }
    }, [isFontLoaded])

    return (
        <div className="App">
            <div id="mobile-checker" />
            <AgenceProvider url={settings.wsURL}>
                <BlocksProvider>
                    <TransactionsProvider >
                        <AccountsProvider>
                            <ForgesProvider>
                                <AssetsProvider>
                                    <StakingProvider>
                                        <AuctionsProvider>
                                            <Suspense fallback={null}>
                                                <I18nSettings>
                                                    <Router routes={routes} context={{
                                                        //global states
                                                        handleUpdateSettings,
                                                        settings,
                                                        //service instances
                                                        agenceService,
                                                        faucetService,
                                                    }}>
                                                        <Suspense fallback={null}>
                                                            <View />
                                                        </Suspense>
                                                    </Router>
                                                </I18nSettings>
                                            </Suspense>
                                        </AuctionsProvider>
                                    </StakingProvider>
                                </AssetsProvider>
                            </ForgesProvider>
                        </AccountsProvider>
                    </TransactionsProvider >
                </BlocksProvider>
            </AgenceProvider>
        </div>
    )

}