import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import { useAgence } from './agence'
import { accountApi } from '../../api/account'
import Keyring from '@polkadot/keyring'

const AccountsContext = React.createContext({})
const useAccountContext = () => {
    return useContext(AccountsContext)
}

const AccountsProvider = ({ children }) => {
    const [keyring, setKeyring] = useState(null)
    const { api, apiReady } = useAgence()

    const accountService = useMemo(() => accountApi(api, apiReady), [api, apiReady])

    useEffect(() => {
        const newKeyring = new Keyring({ type: 'sr25519' })
        setKeyring(newKeyring)
    }, [])

    const fetchAccountKeys = useCallback(async (currentPage, pageSize) => {
        const startIndex = (currentPage - 1) * 10
        const endIndex = startIndex + pageSize

        //TODO: Now we cannot control the number of getKeys, but this needs to be resolved.
        const accountKeys = await accountService.fetchAccountKeys()

        const total_accounts = accountKeys.length
        const AdjustedEndIndex = endIndex > total_accounts ? total_accounts : endIndex

        const accounts = {}
        for (let i = startIndex; i < AdjustedEndIndex; i++) {
            const keyString = accountKeys[i].toString()
            const address = await accountService.fetchAddressByKey(keyString, keyring)
            const account = await accountService.fetchAccountByAddr(address)

            accounts[address] = Object.assign({
                account_number: i + 1,
                address: address,
                freeBalance: account.data.free
            }, account)
        }
        return { accounts, total_accounts }
    }, [accountService, keyring])

    return (
        <AccountsContext.Provider value={{ fetchAccountKeys, accountService, keyring }}>
            {children}
        </AccountsContext.Provider>
    )
}

export { useAccountContext, AccountsProvider }
